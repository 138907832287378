export function printDiv(divName) {
    let printContents = document.getElementById(divName).innerHTML;

    let tnum = Math.random() * 10000;
    tnum = parseInt(tnum);
    const title = 'Window' + tnum.toString();

    let popUpWindow = window.open('', title, 'popup=1,width=400,height=700');
    popUpWindow.document.write('<html>');
    popUpWindow.document.write('<head>');
    popUpWindow.document.write('<title>Juice Receipt</title>');
    popUpWindow.document.write('<link rel="stylesheet" href="/cogs/css/bootstrap.css">');
    popUpWindow.document.write('<link rel="stylesheet" href="/cogs/css/bootstrap-responsive.css">');
    popUpWindow.document.write('<link rel="stylesheet" href="/cogs/css/main.css">');
    popUpWindow.document.write('</head>');
    popUpWindow.document.write('<body onLoad="window.print();setTimeout(self.close(), 1000);" bgcolor="#FFFFFF">');
    popUpWindow.document.write(printContents);
    popUpWindow.document.write('</body>');
    popUpWindow.document.write('</html>');
    popUpWindow.document.close();
    popUpWindow.focus();

    return true;
}
