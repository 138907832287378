import * as L from '../../js/leaflet-src.esm.js';

export function embed(divID, lat, long) {
    var element = document.getElementById(divID);
    element.style = 'height:600px;width:600px;';

    // Create Leaflet map on map element.
    var map = L.map(element);

    // Add OSM tile layer to the Leaflet map.
    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    // Target's GPS coordinates.
    var target = L.latLng(lat, long);

    // Set map's center to target with zoom 14.
    map.setView(target, 16);

    // Set the markers for the map.
    var icon = L.icon({
        iconUrl: 'cogs/img/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'cogs/img/marker-shadow.png',
        shadowSize: [41, 41],
        shadowAnchor: [12, 41]
    });

    // Place a marker on the same location.
    L.marker(target, {icon: icon}).addTo(map);
}
