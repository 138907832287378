export function nawCustomInput(selectID, fieldID) {
    const selectElement = document.getElementById(selectID);
    const customValueInput = document.getElementById(fieldID);

    selectElement.addEventListener('change', () => {
        if (selectElement.value === 'other') {
            customValueInput.disabled = false;
        } else {
            customValueInput.disabled = true;
        }
    });
}
